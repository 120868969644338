export default [
    {
        path: "store",
        component: () => import("../views/Ecommerce/StoreBase"),
        children: [
            {
                path: "",
                name: "store-dashboard",
                meta: {
                    loginRequired: true,
                    title:'Store | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Dashboard")
            },
            {
                path: "new-product",
                name: "add-product",
                meta: {
                    loginRequired: true,
                    title:'Add product | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/AddProduct")
            },
            {
                path: "product/:productid",
                name: "product-detail",
                meta: {
                    loginRequired: true,
                    title:'Product Detail | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/ProductDetail")
            },
            {
                path: "products",
                name: "product-list",
                meta: {
                    loginRequired: true,
                    title:'Products | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/ProductList")
            }, {
                path: "product/:id/review",
                name: "product-review",
                meta: {
                    loginRequired: true,
                    title:'Product Review | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Profile/Review/ProductReview")
            },
            {
                path: "payment-gateway",
                name: "payment-gateway-list",
                meta: {
                    loginRequired: true,
                    title:'Payment Gateways | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/PaymentGateway/List")
            },
            {
                path: "payment-gateway/new",
                name: "payment-gateway-add",
                meta: {
                    loginRequired: true,
                    title:'Add Payment Gateway | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/PaymentGateway/Add")
            },
            {
                path: "catalog",
                name: "catalog-list",
                meta: {
                    loginRequired: true,
                    title:'Catalogs | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Catlogs/List")
            },
            {
                path: "catlog/new",
                name: "catlog-add",
                meta: {
                    loginRequired: true,
                    title:'Add Catalog | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Catlogs/Add")
            },
            {
                path: "couponcode",
                name: "couponcode-list",
                meta: {
                    loginRequired: true,
                    title:'Coupon Code | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/CouponCodes/List")
            },
            {
                path: "couponcode/new",
                name: "couponcode-add",
                meta: {
                    loginRequired: true,
                    title:'Add Coupon Code | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/CouponCodes/Add")
            },
            {
                path: "orders/:status",
                name: "orders-list",
                meta: {
                    loginRequired: true,
                    title:'Orders | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Orders/Index")
            },{
                path: "make/order",
                name: "manual-order",
                meta: {
                    loginRequired: true,
                    title:'Orders | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Orders/ManualOrder/Index")
            },
            {
                path: "customers/:status",
                name: "customers-list",
                meta: {
                    loginRequired: true,
                    title:'Customers | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Customers/Index")
            },
            {
                path: "customers-address/:userId",
                name: "customers-address",
                meta: {
                    loginRequired: true,
                    title:'Customer Addresses | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Customers/CustomerAddress")
            },
            {
                path: "customer/create",
                name: "customers-create",
                meta: {
                    loginRequired: true,
                    title:'Add Customer | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Customers/CreateAndUpdate")
            },
            {
                path: "shipment/:status",
                name: "shipment-list",
                meta: {
                    loginRequired: true,
                    title:'Shipments | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Shipments/Index")
            },
            // {
            //     path: "shipment/new",
            //     name: "shipment-add",
            //     component: () =>
            //         import("../views/Ecommerce/Shipments/Add")
            // },
            {
                path: "attributes",
                name: "attributes-list",
                meta: {
                    loginRequired: true,
                    title:'Attributes | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Attributes/List")
            },
            {
                path: "attributes/new",
                name: "attributes-add",
                meta: {
                    loginRequired: true,
                    title:'Add Attribute | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Attributes/Add")
            },
            {
                path: "brand-manager",
                name: "brand-manager",
                meta: {
                    loginRequired: true,
                    title:'Brands | Yelko'
                },
                component: () => import("../views/Ecommerce/Brand/Index"),
            },

        ]

    }
];

<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
//@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "assets/plugins/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/plugins/line-awesome/css/line-awesome.css";
@import "~vue-snotify/styles/material";

:root {
  --body-bg-color: #f3f6f9;
  --primary-color:#000;
  --secondary-color: #dfface;
  --primary-text-color:#21251e;
  --secondary-text-color: #ffffff;

  //Login
  --login-bg-color:#1f313d;
  --login-primary-color:#00458f;
  --login-secondary-color:#f2883a;

  //buttons
  --button-bg-color:#00458f;
  --button-primary-color:#38d;
  --button-secondary-color:#16b;
  --button-primary-text-color:#00458f;
  --button-secondary-text-color:#f2883a;


  //Sidebar
  --sidebar-primary-color:#000000;
  --sidebar-secondary-color: #5f6368;
  --sidebar-primary-text-color:#ffffff;
  --sidebar-secondary-text-color: #5f6368;
  --sidebar-bg-color: #E5F1F5;
  --sidebar-active-menu-bg-color: #E5F1F5;
  --sidebar-active-menu-text-color: #21251e;
  --sidebar-menu-text-color: #ffffff;

  //Sub Header
  --header-primary-color:#f8f9fa;
  --header-secondary-color: #5f6368;
  --header-primary-text-color:#5f6368;
  --header-secondary-text-color: #ffffff;

  --sub-header-bg-color: blue;
  --sub-header-text-color: blue;
  --color-white: #fff;
  --color-dark: #212121;
}


// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/config.module";


export default {
  name: "YelkoUserApp",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/common/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>

export default [
      {
            path: "cart",
            name: "cart",
            component: () => import("../views/cart/Cart"),
      },
      {
            path: "domain/:domain_name",
            name: "domain",
            meta: {
                  loginRequired: true,
                  title:'Domain | Yelko'
              },
            component: () => import("../views/domain/RegisterDomain"),
      },
      {
            path: "domain/dns-zone-manager/:domain_name",
            name: "dns-management",
            meta: {
                  loginRequired: true,
                  title:'DNS Management | Yelko'
              },
            component: () => import("../views/domain/DnsZoneManager"),
      },
      {
            path: "domain/verification/:domain_name",
            name: "domain-verfication",
            meta: {
                  loginRequired: true,
                  title:'Domain Verification | Yelko'
              },
            component: () => import("../views/domain/DomainVerification"),
      },
      {
            path: "coupon-code",
            name: "coupon-code",
            meta: {
                  loginRequired: true,
                  title:'Coupon Code | Yelko'
              },
            component: () => import("../views/pages/coupon/Coupon"),
      },{
            path: "review",
            name: "review",
            meta: {
                  loginRequired: true,
                  title:'Review | Yelko'
              },
            component: () => import("../views/pages/review/Review"),
      },{
            path: "short-code",
            name: "short-code",
            meta: {
                  loginRequired: true,
                  title:'ShortCode | Yelko'
              },
            component: () => import("../views/pages/ShortCode"),
      }, {
            path: "report",
            name: "report",
            component: () =>
                  import("../views/pages/report/Index"),
            children: [
                  {
                        path: "product/:status",
                        name: "report-product",
                        meta: {
                              loginRequired: true,
                              title:'Product Reports | Yelko'
                          },
                        component: () =>
                              import("../views/pages/report/ProductReport")
                  }, {
                        path: "order",
                        name: "report-order",
                        meta: {
                              loginRequired: true,
                              title:'Order Reports | Yelko'
                          },
                        component: () =>
                              import("../views/pages/report/OrderReport")
                  },
            ]

      },
];
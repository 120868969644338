import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
//import MockService from "./common/mock/mock.service";
import { VERIFY_AUTH } from "./store/auth.module";
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import Snotify from 'vue-snotify';
/* import VueSocialauth from 'vue-social-auth'; */
import VueClipboard from 'vue-clipboard2';
import VueConfirmDialog from 'vue-confirm-dialog';
import Verte from 'verte';
import 'verte/dist/verte.css';
import VueProgressiveImage from 'vue-progressive-image';

Vue.use(VueProgressiveImage, {
  placeholder: 'https://unsplash.it/1920/1080?image=20',
  fallback: '/assets/media/logos/icon-no-image.svg',
  delay: 2000,
  blue: 10
});


Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component(Verte.name, Verte);
Vue.use(Verte);
Vue.use(require('vue-moment'));
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);

Vue.use(Vuelidate);
Vue.use(Snotify);
if (process.env.VUE_APP_ENV === 'production') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
  Vue.config.productionTip = false;
}
require('dotenv').config();
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDJmuvRFb73uKr5J6eYbDQlzsdv0SYgSvk',
    v: '3.26',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

/* Vue.use(VueSocialauth, {

  providers: {
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URL// Your client app URL
    },

    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      redirectUri: process.env.VUE_APP_FACEBOOK_REDIRECT_URL // Your client app URL
    },
  }
}) */
// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";

/** Vue Filters Start */
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {

  window.document.title = to.meta && to.meta.title ? to.meta.title : 'Member Area | Yelko';

  if(to.name!='login' && to.name!='logout' && to.name!='register' && to.name!='reset-password' && to.name!='verification' && to.name!='forgot-password')
  {
    Promise.all([store.dispatch(VERIFY_AUTH, to)]).then(next);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  else
  {
    Promise.all([to]).then(next);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }




});

if (!window.localStorage.getItem('XSRF-TOKEN')) {
  ApiService.get("/sanctum/csrf-cookie").then(() => {
  });
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

import Vue from "vue";
import Router from "vue-router";
import OrganizationRouter from "./routes/organization";
import WebsitesRouter from "./routes/websites";
import StoreRouter from "./routes/ecommerce";
import GeneralRouter from "./routes/general";
import DomainRouter from "./routes/domains";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/login",
            component: () => import("@/views/theme/Base"),
            children: [
                ...OrganizationRouter,
                ...WebsitesRouter,
                ...GeneralRouter,
                ...StoreRouter,
                ...DomainRouter,
                {
                    path: "/dashboard",
                    name: "dashboard",
                    meta: {
                        loginRequired: true,
                        title: 'Dashboard | Yelko'
                    },
                    component: () => import("@/views/pages/Dashboard.vue")
                },
                {
                    path: "/payment-success",
                    name: "payment-success",
                    meta: {
                        loginRequired: true,
                        title:'Payment | Yelko'
                    },
                    component: () => import("@/views/cart/Sucess.vue")
                },
                {
                    path: "/reviews",
                    name: "reviewPersonal",
                    meta: {
                        loginRequired: true,
                        title:'Reviews | Yelko'
                    },
                    component: () => import("@/views/pages/Review.vue")
                },
                {
                    path: "/invoice/:cartId/:invoiceId",
                    name: "invoice",
                    meta: {
                        loginRequired: true,
                        title:'Invoice | Yelko'
                    },
                    component: () => import("@/views/pages/Invoice.vue")
                },
                {
                    path: "/billing",
                    name: "billing",
                    meta: {
                        loginRequired: true,
                        title:'Billing | Yelko'
                    },
                    component: () => import("@/views/pages/Billing.vue")
                },
                {
                    path: "/support",
                    name: "support",
                    meta: {
                        loginRequired: true,
                        title:'Support | Yelko'
                    },
                    component: () => import("@/views/pages/Support.vue")
                },
                {
                    path: "/subscription-plan",
                    name: "subscription-plan",
                    meta: {
                        loginRequired: true,
                        title:'Subscription | Yelko'
                    },
                    component: () => import("@/views/pages/SubscriptionPlan.vue")
                },
                {
                    path: "/my-account",
                    name: "myAccount",
                    meta: {
                        loginRequired: true,
                        title:'Account | Yelko'
                    },
                    component: () => import("@/views/pages/profile/Account.vue")
                },
                {
                    path: "/account-security-and-authentication",
                    name: "myAccount-security",
                    meta: {
                        loginRequired: true,
                        title:'Security | Yelko'
                    },
                    component: () => import("@/views/pages/profile/Security.vue")
                },
                {
                    path: "/builder",
                    name: "builder",
                    meta: {
                        loginRequired: true,
                        title:'Builder | Yelko'
                    },
                    component: () => import("@/views/pages/Builder.vue")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/views/pages/error/Error-1.vue")
        },
        {
            // the 404 route, when none of the above matches
            path: "/error",
            name: "unauthorized",
            component: () => import("@/views/pages/error/Error-5.vue")
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/views/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/views/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/views/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/views/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/views/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/views/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/views/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/views/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    meta: {
                        loginRequired: false,
                        title:'Login | Yelko'
                    },
                    component: () => import("@/views/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    meta: {
                        loginRequired: false,
                        title:'Create your account | Yelko'
                    },
                    component: () => import("@/views/pages/auth/Register")
                },
                {
                    name: "verification",
                    path: "/verification",
                    meta: {
                        loginRequired: false,
                        title:'Verification | Yelko'
                    },
                    component: () => import("@/views/pages/auth/Verification")
                },
                {
                    name: "forgot-password",
                    path: "/forgot-password",
                    meta: {
                        loginRequired: false,
                        title:'Reset password | Yelko'
                    },
                    component: () => import("@/views/pages/auth/ForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "/reset-password",
                    meta: {
                        loginRequired: false,
                        title:'Reset Password | Yelko'
                    },
                    component: () => import("@/views/pages/auth/ResetPassword")
                },
                {
                    name: "social-account-setup",
                    path: "/social-account-setup",
                    meta: {
                        loginRequired: false,
                        title:'Social Account | Yelko'
                    },
                    component: () => import("@/views/pages/auth/SetSocialAccount")
                },
                {
                    path: '/auth/:provider/callback',
                    component: {
                      template: '<div class="auth-component"></div>'
                    }
                  },
            ]
        },



    ]
});

export default [
    {
        path: "domains",
        name: 'domains',
        component: () => import("../views/domain/ManageDomains"),
        children: [
            {
                path: "",
                name: "domains-dashboard",
                meta: {
                    loginRequired: true,
                    title:'Domains Management | Yelko'
                },
                component: () =>
                    import("../views/domain/ManageDomains")
            },
            {
                path: "manage/dns/:domainname",
                meta: {
                    loginRequired: true,
                    title:'DNS Zone Management | Yelko'
                },
                name: "manage-dns",
                component: () =>
                    import("../views/domain/DnsZoneManager")
            },
            {
                path: "verify-ownership/:domainname",
                name: "verify-domain-ownership",
                meta: {
                    loginRequired: true,
                    title:'Domain Ownership Verification | Yelko'
                },
                component: () =>
                    import("../views/domain/ManageDomains")
            },
            {
                path: "manage",
                name: "manage-domains",
                meta: {
                    loginRequired: true,
                    title:'Domain Management | Yelko'
                },
                component: () =>
                    import("../views/domain/ManageDomains")
            }
        ]

    }
];

import ApiService from "@/common/api.service";
const apiService = ApiService;

export default {
      state: {
            // define data here
            cart: {},
      },
      getters: {
            // getters are responsible for changing state property
            // like converting to uppercase and lowercase
            // but doesn't override the value of state
      },
      mutations: {
            // mutation is use for synchoronus progamming
            // mutaion can change value of state
            SET_CART: (state, payload) => {
                  state.cart = payload
            },
      },
      actions: {
            // actions is use for asynchronous programming
            // cannot be able to change state
            GET_CART: (context) => {
                  let url = 'user/cart';
                  apiService.get(url).then(response => {
                        context.commit('SET_CART', response.data)
                  }).catch(error => {
                        context.commit('SET_CART', null)
                  })
            },

            ADD_TO_CART: (context, payload) => {
                  let url = 'user/cart/add-to-cart';
                  apiService.post(url, payload).then(response => {
                        context.commit('SET_CART', response.data.cart)
                  }).catch(error => {
                        context.commit('SET_CART', null)
                  })
            },

            DELETE_CART_ITEM: (context, payload) => {
                  let url = 'user/cart/item/' + payload + '/delete';
                  apiService.delete(url).then(response => {
                        if (response.data)
                              context.commit('SET_CART', response.data)
                        else
                              context.commit('SET_CART', null)
                  }).catch(error => {
                        context.commit('SET_CART', null)
                  })
            },

            REMOVE_DISCOUNT: (context, payload) => {
                  let url = 'user/cart/' + payload + '/remove-code';
                  apiService.get(url).then(response => {
                        if (response.data)
                              context.commit('SET_CART', response.data)
                        else
                              context.commit('SET_CART', null)
                  }).catch(error => {
                        context.commit('SET_CART', null)
                  })
            },
      }
}

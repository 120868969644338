export default [
    {
        path: "organizations",
        name: "organizations",
        component: () => import("../views/organization/Organization"),
        children: [
            {
                path: "",
                    name: "list",
                meta: {
                    loginRequired: true,
                    title:'Organisations | Yelko'
                },
                component: () =>
                    import("../components/organization/OrganizationList")
            },
            {
                path: "new",
                name: "add-organization",
                meta: {
                    loginRequired: true,
                    title:'Add Organisation | Yelko'
                },
                component: () =>
                    import("../components/organization/OrganizationAdd")
            },
            {
                path: ":name/profile/",
                name: "organization-profile",
                meta: {
                    loginRequired: true,
                    title:'Organisation Profile | Yelko'
                },
                component: () =>
                    import("../components/organization/OrganizationProfile")
            },
            {
                path: ":slug/subscription",
                name: "organization-subscription",
                meta: {
                    loginRequired: true,
                    title:'Organisation Subscription | Yelko'
                },
                component: () =>
                    import("../components/organization/SubscriptionPlan")
            },
        ]
    }
];

import ApiService from "@/common/api.service";
import AirlockService from "@/common/airlock.service";
import router from "../router";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const SET_LOGIN = 'setLogin';

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!AirlockService.getToken()
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise(resolve => {
            // ApiService.get("logout").then(({ data }) => {
            /* ApiService.get("/sanctum/csrf-cookie").then(() => { */
            ApiService.post("user/login", credentials)
                .then(({ data }) => {
                    localStorage.setItem("user-token",data.data.token);
                    // console.log("user-token "+data.data.token)
                    context.commit(SET_AUTH, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
            /*      }); */
            // });
        });
    },


    [SET_LOGIN](context, data) {
        return new Promise(resolve => {
            context.commit(SET_AUTH, data);
            resolve(data);
        });
    },
    [LOGOUT](context) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        ApiService.get("user/logout").then(({ data }) => {
            if (window.localStorage.getItem('id_token')) {
                window.localStorage.removeItem('id_token');
                window.location.reload();

            }
            context.commit(PURGE_AUTH);
        });
    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("users", { user: credentials })
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [UPDATE_USER](context, payload) {
        const { email, username, password, image, bio } = payload;
        const user = { email, username, bio, image };
        if (password) {
            user.password = password;
        }

        return ApiService.put("user", user).then(({ data }) => {
            context.commit(SET_AUTH, data);
            return data;
        });
    },
    [VERIFY_AUTH](context) {

        if (!this.state.auth.isAuthenticated) {
            context.commit(PURGE_AUTH);
            router.push({ name: 'login' });
        }
        if (AirlockService.getToken()) {
            ApiService.setHeader();
            ApiService.get("user/verify")
                .then(({ data }) => {
                    context.commit(SET_AUTH, data.data);
                })
                .catch(({ response }) => {
                    context.commit(PURGE_AUTH);
                    window.localStorage.removeItem('id_token');
                    context.commit(SET_ERROR, response.data.data.errors);
                });
        } else {
            context.commit(PURGE_AUTH);
        }
    },

    [UPDATE_USER](context, payload) {
        const { email, username, password, image, bio } = payload;
        const user = { email, username, bio, image };
        if (password) {
            user.password = password;
        }
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        state.errors = {};
        AirlockService.saveToken(state.user.token);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        AirlockService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};

export default [
    {
        path: "websites",
        name: 'websites',
        component: () => import("../views/websites/Websites"),
        children: [
            {
                path: "",
                name: "website-dashboard",
                meta: {
                    loginRequired: true,
                    title:'Website | Yelko'
                },
                component: () =>
                    import("../views/websites/Dashboard")
            },
            {
                path: "trash",
                name: "website-trash-dashboard",
                meta: {
                    loginRequired: true,
                    title:'Website | Yelko'
                },
                component: () =>
                    import("../views/websites/Dashboard")
            },
            {
                path: "start-new-website",
                name: "website-getting-started",
                meta: {
                    loginRequired: true,
                    title:'Getting Started | Yelko'
                },
                component: () =>
                    import("../views/websites/NewWebsite")
            },
            {
                path: "manage/:domainname",
                name: "manage-websites",
                meta: {
                    loginRequired: true,
                    title:'Manage Websites | Yelko'
                },
                component: () =>
                    import("../views/websites/ManageWebsite")
            },
            {
                path: "domains/manage/dns/:domainname",
                name: "manage-dns",
                meta: {
                    loginRequired: true,
                    title:'Manage DNS | Yelko'
                },
                component: () =>
                    import("../views/domain/DnsZoneManager")
            },
            {
                path: "domains/verify-ownership/:domainname",
                name: "verify-domain-ownership",
                meta: {
                    loginRequired: true,
                    title:'Domain Ownership Verification | Yelko'
                },
                component: () =>
                    import("../views/domain/ManageDomains")
            },
            {
                path: "domains/manage",
                name: "manage-domains",
                meta: {
                    loginRequired: true,
                    title:'Manage Domains | Yelko'
                },
                component: () =>
                    import("../views/domain/ManageDomains")
            },
            {
                path: "domains/connect-domain/:domainname",
                name: "manage-custom-domains",
                meta: {
                    loginRequired: true,
                    title:'Manage Custom Domains | Yelko'
                },
                component: () =>
                    import("../views/websites/ConnectDomain")
            },
            {
                path: "pages/:domainname",
                name: "manage-pages",
                meta: {
                    loginRequired: true,
                    title:'Manage Pages | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Page")
            },
            {
                path: "blogs/:domainname",
                name: "manage-blogs",
                meta: {
                    loginRequired: true,
                    title:'Manage Blogs | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Blog")
            },
            {
                path: "blogs/category/:domainname",
                name: "manage-blogs-category",
                meta: {
                    loginRequired: true,
                    title:'Manage Blog category | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/BlogCategory")
            },
            {
                path: "blogs/new/:domainname",
                name: "manage-blogs-new",
                meta: {
                    loginRequired: true,
                    title:'Add Blog | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/AddBlog")
            },
            {
                path: "blogs/edit/:domainname/:id",
                name: "manage-blogs-edit",
                meta: {
                    loginRequired: true,
                    title:'Edit Blog | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/EditBlog")
            },
            {
                path: "settings/:domainname",
                name: "manage-settings",
                meta: {
                    loginRequired: true,
                    title:'Settings | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Setting")
            },
            {
                path: "ecommerce/:domainname",
                name: "manage-ecommerce",
                meta: {
                    loginRequired: true,
                    title:'Ecommerce | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/EcommerceSettings")
            },

            {
                path: "menu/:domainname",
                name: "manage-menu",
                meta: {
                    loginRequired: true,
                    title:'Menus | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Menu")
            },
            {
                path: "media/:domainname",
                name: "manage-media",
                meta: {
                    loginRequired: true,
                    title:'Media | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Media")
            },
            {
                path: "analytics/:domainname",
                name: "manage-analytics",
                meta: {
                    loginRequired: true,
                    title:'Analytics | Yelko'
                },
                component: () =>
                    import("../views/websites/ManageWebsite")
            },
            {
                path: "templates/:domainname",
                name: "manage-templates",
                meta: {
                    loginRequired: true,
                    title:'Templates | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Templates")
            },
            {
                path: "templates/:domainname/:templatename",
                name: "confirm-templates",
                meta: {
                    loginRequired: true,
                    title:'Confirm Template | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/ConfirmTemplate")
            },
            {
                path: "seo/:domainname/:page",
                name: "manage-seo",
                meta: {
                    loginRequired: true,
                    title:'SEO | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Seo")
            },
            {
                path: "seo/:domainname/",
                name: "manage-seo-single",
                meta: {
                    loginRequired: true,
                    title:'SEO | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/Seo-single")
            },
            {
                path: "quickSetup/:domainname",
                name: "quicksetup",
                meta: {
                    loginRequired: true,
                    title:'Quick Setup | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/QuickSetup")
            },
            {
                path: "website/:domainname",
                name: "website-created",
                meta: {
                    loginRequired: true,
                    title:'Website Created | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/WebsiteCreated")
            },
            {
                path: "custom-form/:domainname",
                name: "website-form",
                meta: {
                    loginRequired: true,
                    title:'Froms | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/CustomFormBuilder")
            },
            {
                path: "layouts/:domainname",
                name: "website-layouts",
                meta: {
                    loginRequired: true,
                    title:'Website Layouts | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/LayoutsSetting")
            },
            {
                path: "magage-ssl-certificate/:domainname",
                name: "website-ssl-certificate",
                meta: {
                    loginRequired: true,
                    title:'SSL Certificate | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/SSLManager")
            },
            {
                path: "mail/zoho/account/manage/:domainname",
                name: "website-mail-setup",
                meta: {
                    loginRequired: true,
                    title:'Mail Setup | Yelko'
                },
                component: () =>
                    import("../views/websites/InternalPage/MailManager")
            },
            {
                path: "/subscription/:domainname",
                name: "website-subscription-plan",
                meta: {
                    loginRequired: true,
                    title:'Subscription Plan | Yelko'
                },
                component: () => import("@/views/websites/SubscriptionPlan.vue")
            }, {
                path: ":domainname/troubleshoot",
                name: "website-troubleshoot",
                meta: {
                    loginRequired: true,
                    title:'Troubleshoot | Yelko'
                },
                component: () => import("../views/websites/InternalPage/Troubleshoot"),
            },
            {
                path: ":domainname/notice",
                name: "website-notice",
                meta: {
                    loginRequired: true,
                    title:'Notice | Yelko'
                },
                component: () => import("../views/websites/InternalPage/Notice"),
            },
            {
                path: ":domainname/storefront",
                name: "website-storefront",
                meta: {
                    loginRequired: true,
                    title:'Store Front | Yelko'
                },
                component: () => import("../views/websites/InternalPage/store-setting/StoreFront"),
            },
            {
                path: ":domainname/currency",
                name: "website-currency",
                meta: {
                    loginRequired: true,
                    title:'Currency | Yelko'
                },
                component: () => import("../views/websites/InternalPage/store-setting/Currency"),
            },
            {
                path: ":domainname/invoice",
                name: "website-invoice",
                meta: {
                    loginRequired: true,
                    title:'Invoices | Yelko'
                },
                component: () => import("../views/websites/InternalPage/store-setting/Invoice"),
            },
            {
                path: ":domainname/store-shippment",
                name: "website-shippment",
                meta: {
                    loginRequired: true,
                    title:'Shipment | Yelko'
                },
                component: () => import("../views/websites/InternalPage/store-setting/shippment"),
            },
            {
                path: ":domainname/pixel",
                name: "website-pixel",
                meta: {
                    loginRequired: true,
                    title:'FB Pixel | Yelko'
                },
                component: () => import("../views/websites/widgets/Pixel/Pixel"),
            },
            {
                path: ":domainname/instagram",
                name: "website-instagram",
                meta: {
                    loginRequired: true,
                    title:'instagram | Yelko'
                },
                component: () => import("../views/websites/widgets/Instagram/Instagram"),
            },
            {
                path: ":domainname/fb-chat",
                name: "website-fb-chat",
                meta: {
                    loginRequired: true,
                    title:'Facebook Chat | Yelko'
                },
                component: () => import("../views/websites/widgets/FbChat/FbChat"),
            },
            {
                path: ":domainname/google-analytics",
                name: "website-google-analytics",
                meta: {
                    loginRequired: true,
                    title:'Google Analytics | Yelko'
                },
                component: () => import("../views/websites/widgets/GoogleAnalytics/GoogleAnalytics"),
            },
            {
                path: ":domainname/pop_up",
                name: "website-pop_up",
                meta: {
                    loginRequired: true,
                    title:'PopUp | Yelko'
                },
                component: () => import("../views/websites/InternalPage/WebsitePopUp"),
            },
            {
                path: ":domain_name/professional-service",
                name: "website-professional-service",
                meta: {
                    loginRequired: true,
                    title:'Professional Service | Yelko'
                },
                component: () => import("../views/websites/professional-service/Index"),
            },
            {
                path: ":domainname/shipping",
                name: "shipment-setup",
                meta: {
                    loginRequired: true,
                    title:'Shipping | Yelko'
                },
                component: () =>
                    import("../views/Ecommerce/Shipping/ShippingPickup"),
                children: [
                    {
                        path: "/",
                        meta: {
                            loginRequired: true,
                            title:'Shipping | Yelko'
                        },
                        name: "shipping", component: () =>
                            import("../views/Ecommerce/Shipping/Index"),
                    },
                    {
                        path: "method",
                        meta: {
                            loginRequired: true,
                            title:'Shipping Method | Yelko'
                        },
                        name: "shipping-method", component: () =>
                            import("../views/Ecommerce/Shipping/Method"),
                    },
                    {
                        path: "zone",
                        name: "shipping-zone",
                        meta: {
                            loginRequired: true,
                            title:'Shipping Zone | Yelko'
                        },
                        component: () =>
                            import("../views/Ecommerce/Shipping/Zone/Index"),
                    },
                    {
                        path: "method/pickup",
                        name: "shipping-method-type-pickup",
                        meta: {
                            loginRequired: true,
                            title:'Pickup | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Pickup"),
                    },  {
                        path: "method/pickup/:id",
                        meta: {
                            loginRequired: true,
                            title:'Pickup | Yelko'
                        },
                        name: "shipping-method-type-pickup-update",
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Pickup"),
                    },
                    {
                        path: "method/free",
                        name: "shipping-method-type-free",
                        meta: {
                            loginRequired: true,
                            title:'Free Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Free"),
                    }, {
                        path: "method/free/:id",
                        name: "shipping-method-type-free-update",
                        meta: {
                            loginRequired: true,
                            title:'Free Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Free"),
                    },{
                        path: "method/custom/",
                        name: "shipping-method-type-custom",
                        meta: {
                            loginRequired: true,
                            title:'Custom Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/CustomRate"),
                    },{
                        path: "method/custom/:id",
                        name: "shipping-method-type-custom-update",
                        meta: {
                            loginRequired: true,
                            title:'Custom Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/CustomRate"),
                    },{
                        path: "method/conditional/",
                        name: "shipping-method-type-conditional",
                        meta: {
                            loginRequired: true,
                            title:'Conditional Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Conditional"),
                    },{
                        path: "method/conditional/:id",
                        name: "shipping-method-type-conditional-update",
                        meta: {
                            loginRequired: true,
                            title:'Conditional Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Conditional"),
                    },{
                        path: "method/flat",
                        name: "shipping-method-type-flat",
                        meta: {
                            loginRequired: true,
                            title:'Flat Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/FlatRate"),
                    },{
                        path: "method/flat/:id",
                        name: "shipping-method-type-flat-update",
                        meta: {
                            loginRequired: true,
                            title:'Flat Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/FlatRate"),
                    },
                    // For Other option
                    {
                        path: "method/:carrier_name/flat",
                        name: "shipping-method-other-flat",
                        meta: {
                            loginRequired: true,
                            title:'Flat Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/FlatRate"),
                    },   {
                        path: "method/:carrier_name/conditional",
                        name: "shipping-method-other-conditional",
                        meta: {
                            loginRequired: true,
                            title:'Conditional Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Conditional"),
                    },   {
                        path: "method/:carrier_name/custom",
                        name: "shipping-method-other-custom",
                        meta: {
                            loginRequired: true,
                            title:'Custom Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/CustomRate"),
                    },   {
                        path: "method/:carrier_name/free",
                        name: "shipping-method-other-flat",
                        meta: {
                            loginRequired: true,
                            title:'Free Shipping | Yelko'
                        },
                        component: () => import("../views/Ecommerce/Shipping/MethodTypes/Free"),
                    },

                ]
            },
        ]

    }
];
